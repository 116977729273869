<script lang="ts" setup>
import { scrollTo } from '@/utils/helpers'
interface Props {
  navbarColor?: string
  layoutButtonText: string
}
const props = defineProps<Props>()

const localePath = useLocalePath()

const navStore = useNavStore()

const buttonLink = computed(() => {
  return navStore.simpleNavButtonLink.link
})

const buttonTarget = computed(() => {
  return navStore.simpleNavButtonLink.target
})

const scrollToFrom = () => {
  if (!buttonLink.value) {
    scrollTo('#form-section', 20)
  }
}

const { navbarStyle } = useNavbarStyle(props.navbarColor as string)
</script>

<template>
  <nav class="navi">
    <header class="navigation bg-white" :style="navbarStyle">
      <div class="navigation__header">
        <div class="navigation__header-content">
          <nuxt-link class="navigation-logo" :to="localePath('/')">
            <BaseIconSvg
              :class="[
                'navigation-logo__icon',
                'navigation-logo__icon--mobile',
              ]"
              name="logomark"
            />
            <BaseIconSvg
              :class="['navigation-logo__icon, navigation-logo__icon--desktop']"
              name="logo"
            />
          </nuxt-link>
          <div class="navigation__menu">
            <BaseButton
              id="cta-header"
              class="navigation__cta-button"
              :href="buttonLink || null"
              :target="buttonTarget"
              :title="layoutButtonText"
              filled
              small
              @click="scrollToFrom"
            >
              <template #text>
                {{ layoutButtonText }}
              </template>
            </BaseButton>
          </div>
        </div>
      </div>
    </header>
  </nav>
</template>

<style lang="scss" scoped>
@mixin flex-align($gap) {
  display: flex;
  align-items: center;
  gap: $gap;
}

.container {
  @media (min-width: $breakpoint-md) {
    --container-padding: 54px;
  }

  @media (min-width: $breakpoint-lg) {
    --container-padding: 20px;
  }
}

.navigation-logo {
  color: inherit;
  background: inherit;

  &__icon {
    color: inherit;
    display: grid;
    place-items: center;

    &--mobile {
      width: 32px;
      height: 32px;

      @media (min-width: $breakpoint-md) {
        display: none;
      }
    }

    &--desktop {
      display: none;
      width: 158px;
      height: 32px;

      @media (min-width: $breakpoint-md) {
        display: grid;
      }
    }
  }
}

.navi {
  display: flex;
  height: var(--header-height);
}

.navigation {
  width: 100%;
  top: 0;
  font-size: var(--font-size-paragraph-s);
  position: fixed;
  z-index: 9999;
  background-color: var(--navbar-bg-color);
  color: var(--navbar-text-color);

  &__header {
    height: 88px;
    position: absolute;
    width: 100%;
    top: 0;
    background: inherit;
    z-index: 9999;
    display: flex;
    padding: 0 24px;
  }

  &__header-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__menu {
    @include flex-align(0);
  }

  &__item {
    @include flex-align(var(--space8));

    cursor: pointer;
  }

  &__icon {
    width: 12px;
    transition: transform 0.2s ease-in-out;

    &--active {
      transform: rotate(180deg);
    }
  }

  &__cta-button {
    margin-left: var(--space16);
  }
}
</style>
