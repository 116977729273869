<template>
  <div class="app--wrapper" :class="[isWordBreak]">
    <NavigationSimple
      :layoutButtonText="layoutButtonText"
      :navbarColor="navbarColor"
    />
    <main id="main-content" tabindex="-1">
      <slot />
    </main>
    <div id="notification-outlet" />
    <LazyFooterCustom :footerColor="footerColor" />
  </div>
</template>

<script setup lang="ts">
interface Props {
  navbarColor?: string
  footerColor?: string
  layoutButtonText: string
}
defineProps<Props>()
const { locale } = useI18n()
const isWordBreak = computed(() => (locale.value === 'de' ? 'break-word' : ''))
</script>

<style lang="scss">
.block-scroll {
  height: 100vh;
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
.break-word {
  word-break: break-word;
  overflow-wrap: break-word;
}
</style>
